<template>
  <b-container
    class="pt-5 bg-blue text-white fontStandard bottom_padding fontStandard"
    fluid
  >
    <h3 class="text-center">
      <i class="big_text fas fa-user-edit pb-1" />
      <br />

      <b-badge pill variant="light">
        <strong class="fontStandard text-primary">
          <BTextUppercase>Informations Personnelles</BTextUppercase>
        </strong>
      </b-badge>
    </h3>
    <br />

    <b-form class="mx-auto max_width_400">
      <!-- Formulaire -->
      <b-form-group>
        Compte utilisateur:
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text>
              <i class="fa fa-user"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-input
            id="content_username"
            class="form-control"
            placeholder="Identifiant"
            type="text"
            v-model="content.username"
            :formatter="formatter_lowercase"
            :state="true"
            disabled
            readonly
          />
        </b-input-group>
      </b-form-group>

      <b-form-group>
        Adresse email:
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text>
              <i class="fa fa-envelope"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-input
            id="content_email"
            class="form-control"
            placeholder="Adresse email"
            autocomplete="off"
            type="email"
            v-model="content.email"
            :state="check_mail(content.email)"
          />
          <b-form-invalid-feedback :state="check_mail(content.email)"
            >Indiquez une adresse email correcte</b-form-invalid-feedback
          >
        </b-input-group>
      </b-form-group>
      <b-form-group>
        Fiche personnelle:
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text>
              <i class="fa fa-address-book"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <select class="form-control" v-model="extra_user_info.gender">
            <option value="male" selected>Homme</option>
            <option value="female">Femme</option>
          </select>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text>
              <i class="fa fa-address-book"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-input
            id="extra_user_info_firstname"
            class="form-control"
            placeholder="Prénom"
            type="text"
            v-model="extra_user_info.firstName"
            :formatter="formatter_capitalize"
            maxlength="50"
            :state="check_firstname(extra_user_info.firstName)"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text>
              <i class="fa fa-address-book"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-input
            id="extra_user_info_lastname"
            class="form-control"
            placeholder="Nom"
            type="text"
            v-model="extra_user_info.lastName"
            :formatter="formatter_uppercase"
            maxlength="50"
            :state="check_lastname(extra_user_info.lastName)"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text>
              <i class="fa fa-phone"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-input
            id="extra_user_info_phone"
            class="form-control"
            placeholder="N° téléphone"
            type="text"
            v-model="extra_user_info.phone"
            :state="check_phone(extra_user_info.phone)"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group>
        Date de naissance:
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text>
              <i class="fa fa-birthday-cake"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-datepicker
            id="content_birthday"
            v-model="extra_user_info.age"
            no-highlight-today
            close-button
            dark
            no-flip
            close-button-variant="outline-primary"
            hide-header
          />
          <b-popover
            target="content_birthday"
            placement="topright"
            title="Facultatif"
            variant="info"
            triggers="hover focus"
            content="Date de naissance"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group>
        Adresse / Code postal / Ville:
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text>
              <i class="fa fa-address-card"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-input
            id="extra_user_info_address"
            class="form-control"
            placeholder="Adresse"
            type="text"
            v-model="extra_user_info.adress"
            maxlength="255"
            :state="check_adress(extra_user_info.adress)"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text>
              <i class="fa fa-address-card"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-input
            id="extra_user_info_citycode"
            class="form-control"
            placeholder="Code postal"
            type="number"
            v-model="extra_user_info.citycode"
            maxlength="10"
            :state="check_citycode(extra_user_info.citycode)"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text>
              <i class="fa fa-address-card"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-input
            id="extra_user_info_city"
            class="form-control"
            placeholder="Ville"
            type="text"
            v-model="extra_user_info.city"
            :formatter="formatter_uppercase"
            maxlength="50"
            :state="check_city(extra_user_info.city)"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group v-if="account.axios_data && account.axios_data.picture">
        <b-input-group>
          <b-img
            width="500"
            fluid
            thumbnail
            center
            rounded="top"
            block
            :src="account.axios_data.picture"
            alt="captcha"
          />
          <b-input-group-prepend>
            <b-button @click="renewCaptcha()" variant="primary">
              <i class="fas fa-undo-alt" />
            </b-button>
          </b-input-group-prepend>
          <b-input
            class="form-control"
            placeholder="Captcha"
            type="text"
            v-model="captcha"
            @click="captcha = ''"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-button
          type="submit"
          variant="primary"
          @click="change_account"
          :disabled="checkPersonnalChange"
          block
        >
          <i class="fas fa-user-edit" /> Modifier
        </b-button>
      </b-form-group>

      <!-- Formulaire -->
    </b-form>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import Form from "@/mixins/Form";

export default {
  components: {
    BTextUppercase: () => import("@/components/BTextUppercase"),
  },
  mixins: [Form],
  mounted() {
    this.loadContent();
  },
  beforeDestroy() {
    this.content = this.passwd1 = this.passwd2 = this.extra_user_info = this.captcha = undefined;
  },
  computed: {
    ...mapState(["account", "events"]),
  },
  methods: {
    async change_account(e) {
      e.preventDefault();

      const Obj = {
        username: this.content.username,
        rank: this.content.rank,
        email: this.content.email,

        gender: this.extra_user_info.gender,
        firstName: this.extra_user_info.firstName,
        lastName: this.extra_user_info.lastName,
        phone: this.extra_user_info.phone,
        age: this.extra_user_info.age,
        adress: this.extra_user_info.adress,
        citycode: this.extra_user_info.citycode,
        city: this.extra_user_info.city,

        captcha: this.captcha,
      };

      if (!this.dataChanged()) {
        return this.$root.showToast(
          `Modification`,
          `Aucun changement`,
          "warning",
          1000
        );
        return await this.loadContent();
      }

      await this.$root.axios("put", "api/auth/change", Obj);

      const axios = this.account.axios_data;

      if (axios && axios.confirm_new_email_address) {
        this.$root.showToast(
          `Modification`,
          `Votre adresse email a changé, pour utiliser la nouvelle, vous devez la confirmer dans vos mails, attention vous avez 5 minutes`,
          "warning",
          30000
        );
      }

      if (
        axios &&
        axios.auth_response === "change_login_info" &&
        axios.check_input.length === 0
      ) {
        this.$root.showToast(
          `Modification`,
          `Vos données sont modifiées avec succès`,
          "info",
          2000
        );
        await this.loadContent();
      }
    },
  },
};
</script>
